<div>
    <div class="jumbotron jumbotron-fluid">
        <div class="container">
            <h1 class="display-4">Security Clearance Portal</h1>
            <p class="lead">Thank you for your interest in joining our Hunter Strategy team! You have
            applied for a position that requires a security clearance. In order to proceed, our 
            Facility Security Officer (FSO) needs to verify your clearance status and details.
            </p>
        </div>
    </div>
</div>