

<br>
<div class="container">
    <div class="row">
        <div class="col">
            <mat-form-field appearance="outline">
            <mat-label>First Name</mat-label>
            <input matInput name="first_name" #first_name="ngModel" 
            type="text" placeholder="First Name" [(ngModel)]="model.first_name" 
            required>
            <mat-error>This field is required</mat-error>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="outline">
            <mat-label>Last Name</mat-label>
            <input matInput name="last_name" #last_name="ngModel" 
            type="text" placeholder="Last Name" [(ngModel)]="model.last_name" 
            required>
            <mat-error>This field is required</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field appearance="outline">
            <mat-label>Date of Birth</mat-label>
            <input matInput [matDatepicker]="dob_picker" placeholder="Date of Birth" 
            [(ngModel)]="model.dob" required>
            <mat-datepicker-toggle matSuffix [for]="dob_picker"></mat-datepicker-toggle>
            <mat-datepicker #dob_picker></mat-datepicker>
            </mat-form-field>              
        </div>
        <div class="col"></div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field appearance="outline">
            <mat-label>Social Security Number</mat-label>
            <input matInput name="ssn" #ssn="ngModel" 
            type="tel" minlength="9" maxlength="9" placeholder="999999999" [(ngModel)]="model.ssn" 
            required>
            <mat-error>This field is required</mat-error>
            </mat-form-field>
        </div>
        <div class="col"></div>
    </div>

    <div class="row" *ngIf="sponsor">
        <div class="row">
            <div class="col">
                <label id="example-radio-group-label">What is your marital status? *</label>
                <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="model.marital" required>
                <mat-radio-button class="example-radio-button" *ngFor="let marital of m_statuses" [value]="marital">
                {{marital}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col"></div>
            <div class="row">
            <mat-divider></mat-divider>
            <br>
            </div> 
            <div class="col">
                <br>
                <h2>Tell us about your place of birth</h2>
                <label id="example-radio-group-label">Were you born in the United States? *</label>
                <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="model.outside_us">
                <mat-radio-button class="example-radio-button" *ngFor="let o_status of o_statuses" [value]=o_status.value>
                {{o_status.title}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col"></div>
        </div>
        <div class="row" *ngIf="pobselected">
            <label class="mat-label">Place of Birth</label>
            <div class="col" *ngIf="!outside">
                <div class="row">
                    <div class="col-5">
                        <mat-form-field appearance="outline">
                        <mat-label>City</mat-label>
                        <input matInput name="pob_city" #pob_city="ngModel" 
                        type="text" placeholder="City" [(ngModel)]="model.pob_city" 
                        required>
                        <mat-error>This field is required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field appearance="outline" >
                        <mat-label>State</mat-label>
                        <mat-select name="pob_state" #pob_state="ngModel" 
                        type="text" placeholder="State" [(ngModel)]="model.pob_state" 
                        required>
                            <mat-option *ngFor="let state of states" [value]="state">
                            {{state}}
                            </mat-option> 
                        </mat-select>
                        <mat-error>This field is required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                
            </div>
            <div class="col" *ngIf="outside">
                <div class="row">
                    <div class="col-5">
                        <mat-form-field appearance="outline">
                        <mat-label>City</mat-label>
                        <input matInput name="pob_city" #pob_city="ngModel" 
                        type="text" placeholder="City" [(ngModel)]="model.pob_city" 
                        required>
                        <mat-error>This field is required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field appearance="outline">
                        <mat-label>Country</mat-label>
                        <input matInput name="pob_country" #pob_country="ngModel" 
                        type="text" placeholder="Country" [(ngModel)]="model.pob_country" 
                        required>
                        <mat-error>This field is required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <label class="mat-label">When did you become a U.S. citizen?</label>
                    <div class="col-5">
                        <mat-form-field appearance="outline">
                        <mat-label>Date of Naturalization</mat-label>
                        <input matInput [matDatepicker]="don_picker" placeholder="Date of Naturalization" 
                        [(ngModel)]="model.nat_date" required>
                        <mat-datepicker-toggle matSuffix [for]="don_picker"></mat-datepicker-toggle>
                        <mat-datepicker #don_picker></mat-datepicker>
                        </mat-form-field>              
                    </div>
                </div>
                <div class="row">
                    <label id="example-radio-group-label">Did you renounce your citizenship to your country of birth?</label>
                    <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group"
                    [(ngModel)]="model.citizen_status">
                    <mat-radio-button class="example-radio-button" *ngFor="let c_status of c_statuses" [value]=c_status.value>
                        {{c_status.title}}
                    </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </div>

    <br>
    <div class="row">
        <div class="col-9">
        <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0"
                                 (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                <mat-panel-title>
                    Why do you need this information?
                </mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                    Your date of birth and social security number are only
                    used by our Facility Security Officer (FSO) to verify 
                    your security clearance details within the Defense Information
                    System for Security (DISS).
                </p>
                <br>
                <p>This information will be deleted from our system after your clearance
                    has been verified, or for a maximum of 90 days.
                </p>
            </mat-expansion-panel>
        </mat-accordion>
        </div>
        <div class="col-3"></div>
    </div>
    <br>
    <div class="row">
        <mat-divider></mat-divider>
    </div>
    <br>
    <br>
    <div class="row">
        <div class="col-5">
            <button mat-raised-button color="primary" 
            style="border-radius: 20px; height:100%; width: 100%;"
            (click)="submit()">Submit Your Information</button>
        </div>
        <div class="col"></div>
    </div>
    
</div>

