import { Component, Input, OnInit } from '@angular/core';
import { Prospect } from '../models/prospect-class';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ApiServiceService } from '../api-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-onboard-form',
  templateUrl: './onboard-form.component.html',
  styleUrls: ['./onboard-form.component.css']
})
export class OnboardFormComponent implements OnInit {
  @Input() model: Prospect;

  panelOpenState = false;
  m_statuses: string[] = ['Single', 'Married', 'Divorced', 'Legally Separated', 'Widowed'];
  outsideUS: boolean;
  o_statuses: object[] = [{title:'Yes, I was born in the United States.',value:'y'},
                          {title:'No, I was born outside of the United States.',value:'n'}];
  c_statuses: object[] = [{title:'Yes, I renounced my citizenship.',value:'renounced'},
                          {title:' No, I am a dual citizen of the United States and my country of birth.',value:'dual'}];
  states: string[] = ['AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE',
                      'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 
                      'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT', 
                      'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 
                      'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UM', 'UT', 
                      'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'];
  pob_state: string;
  renounce: boolean;
  loading = false
  submitted = false
  resp!:any
  constructor(private api: ApiServiceService, private router:Router) { }

  ngOnInit(){
    
  }
  submit(){
    this.loading = true
    this.submitted = true
    if (this.model.outside_us == 'y'){
      this.model.citizen_status = 'US'
      this.model.pob_country = 'US'
    }
    this.model.u_type="prospect"
    
   this.api.sendInfo(this.model).subscribe(response => {
      this.resp = response
      this.loading = false
      this.router.navigate(['success'])
   })
  }
  
  get collapsed(){
    return true
  }
  get sponsor(){
    return true
  }
  get outside(){
    if (this.model.outside_us == 'y'){
      return false
    }
    return true
  }
  get pobselected(){
    if (this.model.outside_us == ''){
      return false
    }
    return true
  }
}
