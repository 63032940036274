export class Prospect{

    constructor(
        public ID: string,
        public u_type: string,
        public a_type: string,
        public s_type: string,
        public hiring_mgr: string,
        public recruiter: string,
   
        public first_name: string,
        public last_name: string,
        public email: string,
        public dob: string,
        public ssn: string,
        public marital: string,

        public outside_us: string,
        public pob_city: string,
        public pob_state: string,
        public pob_country: string,
        public nat_date: string,
        public citizen_status: string,
        public clearance: string,
        public effective_date: string,
        public prospect_TTL: number
        
    ){}



}