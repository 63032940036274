import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiServiceService } from '../api-service.service';
import { AuthService } from '../auth.service';
import { Prospect } from '../models/prospect-class';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css']
})
export class FormsComponent implements OnInit {
  @Output() public emitter = new EventEmitter();

  states = ['AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE',
    'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY',
    'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT',
    'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK',
    'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UM', 'UT',
    'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY']

  genders = ['Male', 'Female', 'Other']



  model = new Prospect('', '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '',
    '', '', '', '', null);
  action = ''
  token = ''
  constructor(private activatedRoute: ActivatedRoute, private service: ApiServiceService, private auth: AuthService) {
    this.model = this.auth.getInfo(this.auth.getToken())
  }

  ngOnInit(): void {
    this.auth.getInfo(this.auth.getToken())
  }
  outsideUS = false
  get collapsed() {
    return true
  }
  get sponsor() {
    return true
  }
  get outside() {
    return this.outsideUS
  }
  get pobselected() {
    if (this.outsideUS == undefined) {
      return false
    } return true
  }
}
