import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' })
};

const endPoint = `${environment.apiUrl}prospects`

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {

  constructor(private http: HttpClient, private auth: AuthService) { }

  public sendInfo(model) {
    return this.http.put(`${endPoint}/${model.ID}`, model, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.auth.getToken()
      }
    })


  }
} 