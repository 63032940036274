import { ReturnStatement } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { Prospect } from './models/prospect-class';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
constructor(private activatedRoute: ActivatedRoute ) {}


public tokenizer(token){
  this.setToken(token)
  return this.getAuthStatus()
}

public getToken(){
  return localStorage.getItem('token')
}

public setToken(token){
  localStorage.setItem('token',token)
}

public decodeToken(token){
  return jwt_decode(token)
}

public getAuthStatus(){
  if (this.invalidTokenCheck() || this.expiredTokenCheck()){
    return false
  } return true
}

public invalidTokenCheck(){
  try {
    let token = jwt_decode(this.getToken())
  } catch (error) {
    return true
  }
  return false
}

public expiredTokenCheck(){
  let token = JSON.stringify(jwt_decode(this.getToken()))
  let exp = JSON.parse(token).exp
  let dateNow = Date.now()/1000
  if (dateNow >= exp){
    return true
  }
  return false
}

public getInfo(token):Prospect|any{
  var info = jwt_decode(token)
  delete info['exp']
  return info
}





}
