import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.css']
})
export class DriverComponent implements OnInit {

  constructor(private auth: AuthService, private router: Router, private activatedRoute: ActivatedRoute) {
    
   }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
    if (this.auth.tokenizer(params['token'])){
      this.router.navigate(['forms'])
    }
    })
  }
  get expired(){
    return this.auth.expiredTokenCheck()
  }
 

}

