import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { BlankPageComponent } from './blank-page/blank-page.component';
import { DriverComponent } from './driver/driver.component';
import { FormsComponent } from './forms/forms.component';
import { OnboardFormComponent } from './onboard-form/onboard-form.component';
import { SuccessComponent } from './success/success.component';

const routes: Routes = [
  {path: 'user',component: DriverComponent},
  {path: 'success',component: SuccessComponent},
  {path: 'forms',component: FormsComponent,canActivate: [AuthGuard]},
  {path: '**', component: BlankPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
