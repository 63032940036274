<br>
<footer class="footer" style="background-color: #D8431A;">
    <div class="container">
    <div class="row">
      <div class="col">
        <div class="row">
          <img src="assets/img/HS-Logo-White.png" alt="Hunter Logo" tabindex="0" class=img50>
        </div>
        <div class="row">
          <div class="col-1">
            <a id="header_social_linkedin" class="text-white"
              href="https://www.linkedin.com/company/hunterstrategy/about/" aria-label="LinkedIn">
              <i class="fa fa-linkedin fa-2x"></i>
            </a>
          </div>
          <div class="col-1">
            <a id="header_social_github" class="text-white" 
              href="https://github.com/hunterstrategy/" aria-label="GitHub">
              <i class="fa fa-github fa-2x"></i>
          </a>
          </div>
          <div class="col-1">
            <a id="header_social_medium" class="text-white" 
            href="https://medium.com/hunter-strategy" aria-label="Medium">
            <i class="fa fa-medium fa-2x"></i>
        </a>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <p>3711 Macomb Street Northwest, Office 3B, Washington, DC 20016</p>
        </div>
        <div class="row">
          <p>(703) 935-HUNT  |  Toll Free (877) 366-0696  |  hr@hunterstrategy.net</p>
        </div>
      </div>
    </div>
  </div>
  </footer>

